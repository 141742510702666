/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import store_d3092474 from '/home/pbk/pim/frontend/modules/@ergonode/ui/src/store/draggable/index.js';
import store_68a43298 from '/home/pbk/pim/frontend/modules/@ergonode/attributes/src/store/attribute/index.js';
import store_56523583 from '/home/pbk/pim/frontend/modules/@ergonode/attribute-groups/src/store/attributeGroup/index.js';
import store_b8ba6e7e from '/home/pbk/pim/frontend/modules/@ergonode/authentication/src/store/authentication/index.js';
import store_bf964642 from '/home/pbk/pim/frontend/modules/@ergonode/categories/src/store/category/index.js';
import store_0321a7cb from '/home/pbk/pim/frontend/modules/@ergonode/category-trees/src/store/categoryTree/index.js';
import store_0ad08554 from '/home/pbk/pim/frontend/modules/@ergonode/comments/src/store/comment/index.js';
import store_0f19c558 from '/home/pbk/pim/frontend/modules/@ergonode/conditions/src/store/condition/index.js';
import store_eb621048 from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/unit/index.js';
import store_3075c3d3 from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/tab/index.js';
import store_5bab03fc from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/list/index.js';
import store_e8d25e0a from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/feedback/index.js';
import store_6bb247ac from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/dictionaries/index.js';
import store_8ef25fbe from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/core/index.js';
import store_3be92cec from '/home/pbk/pim/frontend/modules/@ergonode/core/src/store/alert/index.js';
import store_92abc058 from '/home/pbk/pim/frontend/modules/@ergonode/channels/src/store/channel/index.js';
import store_196b7209 from '/home/pbk/pim/frontend/modules/@ergonode/media/src/store/media/index.js';
import store_7db55414 from '/home/pbk/pim/frontend/modules/@ergonode/notifications/src/store/notification/index.js';
import store_34e0a92e from '/home/pbk/pim/frontend/modules/@ergonode/collections/src/store/collection/index.js';
import store_6cb16f54 from '/home/pbk/pim/frontend/modules/@ergonode/products/src/store/product/index.js';
import store_41fd35e8 from '/home/pbk/pim/frontend/modules/@ergonode/product-statuses/src/store/productStatus/index.js';
import store_f09f8a26 from '/home/pbk/pim/frontend/modules/@ergonode/product-templates/src/store/productTemplate/index.js';
import store_6d696dd4 from '/home/pbk/pim/frontend/modules/@ergonode/segments/src/store/segment/index.js';
import store_eeee7446 from '/home/pbk/pim/frontend/modules/@ergonode/status-transitions/src/store/statusTransition/index.js';
import store_2921a9be from '/home/pbk/pim/frontend/modules/@ergonode/roles/src/store/role/index.js';
import store_2ef0bc54 from '/home/pbk/pim/frontend/modules/@ergonode/users/src/store/user/index.js';
import store_098151be from '/home/pbk/pim/frontend/modules/@ergonode/import/src/store/import/index.js';
import store_2c8a80ee from '/home/pbk/pim/frontend/modules/@ergonode/workflow/src/store/workflowConditions/index.js';
import store_000da8be from '/home/pbk/pim/frontend/modules/@ergonode/workflow/src/store/workflow/index.js';

export default {
	draggable: store_d3092474,
	attribute: store_68a43298,
	attributeGroup: store_56523583,
	authentication: store_b8ba6e7e,
	category: store_bf964642,
	categoryTree: store_0321a7cb,
	comment: store_0ad08554,
	condition: store_0f19c558,
	unit: store_eb621048,
	tab: store_3075c3d3,
	list: store_5bab03fc,
	feedback: store_e8d25e0a,
	dictionaries: store_6bb247ac,
	core: store_8ef25fbe,
	alert: store_3be92cec,
	channel: store_92abc058,
	media: store_196b7209,
	notification: store_7db55414,
	collection: store_34e0a92e,
	product: store_6cb16f54,
	productStatus: store_41fd35e8,
	productTemplate: store_f09f8a26,
	segment: store_6d696dd4,
	statusTransition: store_eeee7446,
	role: store_2921a9be,
	user: store_2ef0bc54,
	import: store_098151be,
	workflowConditions: store_2c8a80ee,
	workflow: store_000da8be,
};
